<script setup>
const props = defineProps(['items']);

const elementsRefs = ref(null);
const elementsVisibility = ref([]);
let observer = false;

onMounted(() => {
  observer = new IntersectionObserver(
    (entry) => {
      if (entry.isIntersecting) {
        elementsVisibility.value = true;
      }
    },
    { threshold: 0.1 }
  );

  if (elementsRefs.value) {
    observer.observe(elementsRefs.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});

const { t } = useI18n();
const localePath = useLocalePath();

const getLink = (route) => {
  return route.length > 0 && route.includes('___') ? { name: route } : localePath(route);
};
</script>

<template>
  <div ref="elementsRefs" class="breadcrumbs" :class="{ 'visible animate__animated animate__fadeInDown': elementsVisibility, invisible: !elementsVisibility }">
    <div class="breadcrumbs__item">
      <nuxt-link :to="localePath('index')" class="breadcrumbs__link">NGP</nuxt-link>
    </div>
    <div v-for="(item, key) in items" :key="key" class="breadcrumbs__item">
      <nuxt-link v-if="item.route" :to="getLink(item.route)" class="breadcrumbs__link">{{ item.title }}</nuxt-link>
      <span v-else>
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.breadcrumbs {
  display: none;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  border-bottom: 1px solid $black;
  white-space: nowrap;

  @include mq($sm) {
    display: flex;
  }

  &__item {
    position: relative;
    font-size: 13px;
    margin-left: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-bottom: 8px;

    &:before {
      position: absolute;
      top: 50%;
      left: -14px;
      width: 6px;
      height: 6px;
      border-top: 1px solid $black;
      border-right: 1px solid $black;
      transform: translateY(-50%) rotate(45deg);
      content: '';
    }

    &:first-child {
      margin-left: 0;

      &:before {
        display: none;
      }
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
